<template>
    <LiefengContent>
        <template v-slot:title>{{ `管理员列表` }}</template>
         <template v-slot:toolsbarRight>
            <Form :label-colon="true" :inline="true" class="search">
                <Button type="info" :loading="btnLoading" @click="syncDeviceAuth">刷新</Button>
            </Form>
        </template>
        <template v-slot:contentArea>
            <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                :loading="loading"
                :fixTable="true"
                :curPage="page"
                :total="total"
                :page-size="pageSize"
                :pagesizeOpts="[20, 30, 50, 100]"
                @hadlePageSize="hadlePageSize"
                @tableSelect="tableSelect"
                :hidePage="true"
            ></LiefengTable>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"

export default {
    components: {
        LiefengContent,
        LiefengTable,
    },
    data() {
        return {
            talbeColumns: [
                {
                    title: "姓名",
                    align: "center",
                    minWidth: 100,
                    key: "username",
                },
                {
                    title: "手机号",
                    align: "center",
                    minWidth: 100,
                    key: "mobile",
                },
            ],
            tableData: [],
            page: 1,
            pageSize: 20,
            loading: false,
            total: 0,
            btnLoading:false
        }
    },
    created() {
        this.getList()
    },
    methods: {
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        async getList() {
            this.loading = true
            await this.$get("/gateway/api/sysmartscreen/app/terminal/getTerminalByCode", {
                code:this.$route.query.terminalUuid
            }).then(res => {
                this.loading = false
                this.tableData = res.data.userList
            })
        },
        async syncDeviceAuth(){
            this.btnLoading = true
            await this.$get("/gateway/api/sysmartscreen/pc/terminal/syncDeviceAuth",{
                orgCodeSelf:this.$route.query.orgCodeSelf
            }).then(res=>{
                this.btnLoading = false
                if(res.code == 200){
                    this.$Message.success({
                        content:'刷新成功',
                        background:true
                    })
                    return
                }else{
                    this.$Message.error({
                        content:'刷新失败',
                        background:true
                    })
                    return
                }
            })
        }
    },
}
</script>
